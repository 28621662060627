import React, { Component } from 'react'
import Header from '../Header'
import Hero from '../Hero'
import About from '../About'
import Experience from '../Experience'
import './index.css'

class LandingPage extends Component {
  render() {
    return (
      <div className='app-wrapper'>
        <Header />
        <Hero />
        <About />
        <Experience />
      </div>
    )
  }
}

export default LandingPage
