import React from 'react'
import './index.css'

const Menu = () => (
  <ul className='menu'>
    <li>
      <a href='#'>About me</a>
    </li>
    <li>
      <a href='#'>Experience</a>
    </li>
    <li>
      <a href='#'>Education</a>
    </li>
  </ul>
)

export default Menu
