import React from 'react'
import './index.css'

const Experience = () => (
  <div className='experience'>
    <h2>Experience</h2>
  </div>
)

export default Experience
