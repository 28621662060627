import React, { Fragment } from 'react'
import Typed from 'react-typed'
import './index.css'
import * as text from '../../constants/heroConstants'

const strings = [
  text.js,
  text.es,
  text.react,
  text.redux,
  text.babel,
  text.webpack
]

const Hero = () => (
  <div className='hero'>
    <p>{text.defaultText}</p>
    {text.secondLine}
    <Typed
       strings={strings}
       smartBackspace={false}
       loop={true}
       typeSpeed={120}
       backSpeed={80}
    />
  </div>
)

export default Hero
