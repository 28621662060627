import React, { PureComponent } from 'react'
import Menu from './Menu'
import logo from '../../assets/logo.png'
import './index.css'

const Header = () => (
  <div className='header-wrapper'>
    <div className='header'>
      <span className='logo'>
        <img src={logo} alt='vrinceanu.com' />
      </span>
      <Menu />
    </div>
  </div>
)

export default Header
