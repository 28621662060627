import React from 'react'
import './index.css'

const About = () => (
  <div className='about'>
    <h2>About me</h2>
    <p>I am a 27 years old Front End Developer, based in Iasi, Romania. I love JavaScript and Front End Development. Currently I am writing React apps, using Webpack, Babel, Redux(if needed) and other cool libraries if they are really needed, well
documented and if their bundle size is decent.</p>
    <p>I like to do cool stuff, implement amazing interfaces and their functionality, following the specs and almost pixel perfect, so the user can have the user experience the UX team created. Also, when I have ideas, I do share them with the team and discuss if they are a good fit in the project.</p>
    <p>You can reach me out at: <a href='mailto:hello@vrinceanu.com'>hello@vrinceanu.com</a></p>
  </div>
)

export default About
